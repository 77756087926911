import React, { useState } from 'react';
import JSZip from 'jszip';
import './AddCar.css';
import { apiUrl } from '../config.js';

// Utility function to format text
const formatText = (text) => {
  // Trim spaces and capitalize first letter of each word
  return text.trim().replace(/\b\w/g, char => char.toUpperCase());
};

const AddCar = () => {
  const [carModel, setCarModel] = useState('');
  const [carColor, setCarColor] = useState('');
  const [carImages, setCarImages] = useState([]);
  const [carFeatures, setCarFeatures] = useState('');
  const [carMileage, setCarMileage] = useState('');
  const [carRegistered, setCarRegistered] = useState(false);
  const [carTouchUps, setCarTouchUps] = useState('');
  const [carGrade, setCarGrade] = useState('');
  const [carPrice, setCarPrice] = useState('');
  const [carCompany, setCarCompany] = useState('');
  const [carType, setCarType] = useState('');
  const [fileType, setFileType] = useState('images');
  const [zipFile, setZipFile] = useState(null);
  const [extractedImageNames, setExtractedImageNames] = useState([]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Format fields
    const formattedModel = formatText(carModel);
    const formattedColor = formatText(carColor);
    const formattedCompany = formatText(carCompany);
    const formattedType = formatText(carType);
    const formattedMileage = formatText(carMileage);
    const formattedTouchUps = formatText(carTouchUps);
    const formattedGrade = formatText(carGrade);
    const formattedFeatures = formatText(carFeatures);
    const formattedPrice = carPrice.trim();

    try {
      const formData = new FormData();
      formData.append('model', formattedModel);
      formData.append('color', formattedColor);
      formData.append('company', formattedCompany);
      formData.append('mileage', formattedMileage);
      formData.append('type', formattedType);
      formData.append('registered', carRegistered);
      formData.append('touch_up', formattedTouchUps);
      formData.append('grade', formattedGrade);
      formData.append('features', formattedFeatures);
      formData.append('price', formattedPrice);

      carImages.forEach((file) => {
        formData.append('carImages', file);
      });

      const response = await fetch(`${apiUrl}/addcar`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      console.log('Success:', data);
      alert('Car added successfully!');
      resetForm();
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to add car.');
    }
  };

  const resetForm = () => {
    setCarModel('');
    setCarColor('');
    setCarCompany('');
    setCarType('');
    setCarImages([]);
    setCarFeatures('');
    setCarRegistered(false);
    setCarTouchUps('');
    setCarGrade('');
    setCarPrice('');
    setFileType('images');
    setZipFile(null);
    setExtractedImageNames([]);
  };

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    if (fileType === 'images') {
      setCarImages((prevImages) => [...prevImages, ...files]);
    } else if (fileType === 'zip') {
      setZipFile(files[0]); // Assuming only one ZIP file
    }
  };

  const handleUnzip = async () => {
    if (zipFile) {
      const zip = new JSZip();
      try {
        const zipContent = await zipFile.arrayBuffer();
        const zipFiles = await zip.loadAsync(zipContent);

        const imageNames = [];
        const imageFiles = [];

        for (const [filename, zipFile] of Object.entries(zipFiles.files)) {
          if (!zipFile.dir && /\.(jpg|jpeg|png|gif)$/i.test(filename)) { // Handle various image formats
            imageNames.push(filename);
            const fileData = await zipFile.async('blob');
            imageFiles.push(new File([fileData], filename, { type: zipFile._data.type }));
          }
        }

        console.log('Extracted Image Names:', imageNames); // Debugging
        setExtractedImageNames(imageNames);
        setCarImages(imageFiles);
      } catch (error) {
        console.error('Error extracting ZIP file:', error);
        alert('Failed to extract ZIP file.');
      }
    }
  };

  return (
    <div className="container">
      <h1 className="text-center mb-4">Add New Car</h1>
      <div className="form-container">
        <form onSubmit={handleFormSubmit} encType="multipart/form-data">
          <div className="form-group">
            <label htmlFor="carModel">Car Model</label>
            <input
              type="text"
              className="form-control"
              id="carModel"
              name="model"
              value={carModel}
              onChange={(e) => setCarModel(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="carColor">Car Color</label>
            <input
              type="text"
              className="form-control"
              id="carColor"
              name="color"
              value={carColor}
              onChange={(e) => setCarColor(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="carMileage">Car Mileage</label>
            <input
              type="text"
              className="form-control"
              id="carMileage"
              name="mileage"
              value={carMileage}
              onChange={(e) => setCarMileage(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="carCompany">Car Company</label>
            <input
              type="text"
              className="form-control"
              id="carCompany"
              name="company"
              value={carCompany}
              onChange={(e) => setCarCompany(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="carType">Car Type</label>
            <input
              type="text"
              className="form-control"
              id="carType"
              name="type"
              value={carType}
              onChange={(e) => setCarType(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="fileType">Upload Type</label>
            <select
              id="fileType"
              className="form-control"
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
            >
              <option value="images">Images</option>
              <option value="zip">ZIP File</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="carImages">Car Images</label>
            <input
              type="file"
              className="form-control-file"
              id="carImages"
              name="carImages"
              accept={fileType === 'zip' ? 'application/zip' : 'image/*'}
              onChange={handleFileChange}
              multiple
              required
            />
            {fileType === 'zip' && <button type="button" className="btn btn-secondary mt-2" onClick={handleUnzip}>Unzip and Show Images</button>}
            <small className="form-text text-muted">Upload {fileType === 'zip' ? 'a ZIP file containing images' : 'images'}</small>
          </div>
          {fileType === 'zip' && extractedImageNames.length > 0 && (
            <div className="form-group">
              <label>Extracted Image Names:</label>
              <ul>
                {extractedImageNames.map((name, index) => (
                  <li key={index}>{name}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="carFeatures">Features</label>
            <input
              type="text"
              className="form-control"
              id="carFeatures"
              name="features"
              value={carFeatures}
              onChange={(e) => setCarFeatures(e.target.value)}
            />
            <small className="form-text text-muted">Enter features separated by commas (e.g., Leather Seats, Sunroof).</small>
          </div>
          <div className="form-check">
            <label htmlFor="carRegistered" className="form-check-label">Registered</label>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="carRegistered"
                name="registered"
                checked={carRegistered}
                onChange={(e) => setCarRegistered(e.target.checked)}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="carTouchUps">Touch Ups</label>
            <input
              type="text"
              className="form-control"
              id="carTouchUps"
              name="touch_up"
              value={carTouchUps}
              onChange={(e) => setCarTouchUps(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="carGrade">Grade</label>
            <input
              type="text"
              className="form-control"
              id="carGrade"
              name="grade"
              value={carGrade}
              onChange={(e) => setCarGrade(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="carPrice">Price (PKR)</label>
            <input
              type="text"
              className="form-control"
              id="carPrice"
              name="price"
              value={carPrice}
              onChange={(e) => setCarPrice(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">Add Car</button>
        </form>
      </div>
    </div>
  );
};

export default AddCar;
