import React from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './Footer.js';

const FooterWrapper = () => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  return !isAdminRoute && <Footer />;
};

export default FooterWrapper;
