import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { apiUrl } from '../config.js';

const OutOfStock = () => {
  const [cars, setCars] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchOutOfStockCars();
  }, []);

  const fetchOutOfStockCars = async () => {
    try {
      const response = await fetch(`${apiUrl}/outofstock`);
      if (!response.ok) throw new Error('Failed to fetch out-of-stock cars');
      const data = await response.json();
      setCars(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching out-of-stock cars:', error);
      setError('Failed to fetch out-of-stock cars. Please try again.');
    }
  };

  return (
    <div className="container px-md-3 px-lg-4">
      <h1 className="text-center mb-4">Out of Stock Cars</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Car ID</th>
              <th>Uploaded At</th>
              <th>Updated At</th>
              <th>Color</th>
              <th>Model</th>
              <th>Mileage</th>
              <th>Registered</th>
              <th>Grade</th>
              <th>Features</th>
              <th>Touch Up</th>
              <th>Image</th>
              <th>Price</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {cars.map((car) => (
              <tr key={car.car_id}>
                <td>{car.car_id}</td>
                <td>{car.createdAt ? new Date(car.createdAt).toLocaleDateString() : 'N/A'}</td>
                <td>{car.updatedAt ? new Date(car.updatedAt).toLocaleDateString() : 'N/A'}</td>
                <td>{car.color}</td>
                <td>{car.model}</td>
                <td>{car.mileage}</td>
                <td>{car.registered ? 'True' : 'False'}</td>
                <td>{car.grade}</td>
                <td>{car.features}</td>
                <td>{car.touch_up}</td>
                <td><img src={car.image} alt="Car" className="img-fluid" style={{ maxWidth: '100px' }} /></td>
                <td>${car.price}</td>
                <td>
                  <Link to={`/admin/edit/${car.car_id}`} className="btn btn-primary mr-2 mb-2">Edit</Link>
                  {/* Add other actions here if needed */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OutOfStock;
