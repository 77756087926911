import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from '../Components/Navbar.js';
import Landingpage from './Landingpage.js';
import Cars from './Cars.js';
import Aboutus from './Aboutus.js';
import Login from '../Components/Login.js';
import Signup from '../Components/SignUp.js';
import Myprofile from './Myprofile.js';
import AdminPanel from '../adminpanel/AdminPanel.js';
import Ticket from './Ticket.js';
import NotFound from './NotFound.js';
import Articles from '../Components/Articles.js';
import FooterWrapper from '../Components/FooterWrapper.js';
import { authUrl, userUrl } from '../config.js'; // Ensure userUrl is defined in your config
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const getQueryParams = () => {
      const params = new URLSearchParams(window.location.search);
      return {
        token: params.get('token'),
      };
    };

    const handleOAuthCallback = () => {
      const { token } = getQueryParams();

      if (token) {
        // Store the token in localStorage
        localStorage.setItem('token', token);
        window.history.replaceState({}, document.title, window.location.pathname); // Remove the query params
        fetchUserProfile(token);
      } else {
        console.error('Token not found in query parameters');
      }
    };

    const fetchUserProfile = async (token) => {
      try {
        const response = await fetch(`${authUrl}/login/success`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          localStorage.setItem('user', JSON.stringify(data.user));
          setIsAuthenticated(true);
          console.log(data.user.id)
          
          // Fetch user details from another endpoint to get the role
          const userResponse = await fetch(`${userUrl}/user/${data.user.id}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (userResponse.ok) {
            const userDetails = await userResponse.json();
            const userRole = userDetails.user.role; // Assuming the role is directly available
            const userId = userDetails.user.id; // Assuming the role is directly available

            localStorage.setItem('role', userRole);
            localStorage.setItem('id', userId);

            setIsAdmin(userRole === 'admin');
          } else {
            console.error('Failed to fetch user details');
            setIsAdmin(false);
          }
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        setIsAuthenticated(false);
      }
    };

    if (window.location.search) {
      handleOAuthCallback();
    } else {
      const token = localStorage.getItem('token');
      if (token) {
        fetchUserProfile(token);
      } else {
        setIsAuthenticated(false);
      }
    }
  }, []);

  const PrivateRoute = ({ element, isAuthenticated, isAdmin, isAdminRoute }) => {
    if (!isAuthenticated) {
      return <Navigate to="/login" replace />;
    }

    if (isAdminRoute && !isAdmin) {
      return <Navigate to="/" replace />;
    }

    return element;
  };

  return (
    <Router>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Navbar isAdmin={isAdmin} isLoggedIn={isAuthenticated} />
        <div style={{ flex: '1' }}>
          <Routes>
            <Route path="/" element={<Landingpage />} />
            <Route path="/cars" element={<Cars />} />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/profile" element={isAuthenticated ? <Myprofile /> : <Navigate to="/login" replace />} />
            <Route path="/admin/*" element={<PrivateRoute element={<AdminPanel />} isAuthenticated={isAuthenticated} isAdmin={isAdmin} isAdminRoute={true} />} />
            <Route path="/contactus" element={<Ticket></Ticket>} />
            <Route path="/articles" element={<Articles />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <FooterWrapper />
      </div>
    </Router>
  );
};

export default App;
