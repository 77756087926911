import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { authUrl, userUrl } from '../config.js';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import LoadingScreen from '../Components/LoadingScreen.js';

const MyProfile = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('token');
        
        if (!token) {
          throw new Error('No token found');
        }

        
        const authResponse = await fetch(`${authUrl}/login/success`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          
        
        });

        if (!authResponse.ok) {
          throw new Error('Network response was not ok');
        }

        const authData = await authResponse.json();
        
        
        const userResponse = await axios.get(`${userUrl}/users?username=${authData.user.displayName}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser({ ...authData.user, ...userResponse.data.user });
      } catch (error) {
        console.error('Error fetching user profile:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    window.location.href = '/login'; 
  };

  if (loading) {
    return <LoadingScreen></LoadingScreen>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!user) {
    return <div>No user data available</div>;
  }

  return (
    <div className="container mt-5">
      <div className="card mx-auto" style={{ maxWidth: '800px' }}>
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-md-4 text-center mb-3 mb-md-0">
              <img
                src={user.picture || 'default-profile-pic.jpg'}
                alt="Profile"
                className="img-fluid rounded-circle"
                style={{ maxWidth: '150px' }}
              />
            </div>
            <div className="col-md-8">
              <h4 className="card-title">{user.displayName}</h4>
              <p className="card-text"><strong>Email:</strong> {user.email}</p>
              <p className="card-text"><strong>Full Name:</strong> {user.displayName}</p>
              <p className="card-text"><strong>Role:</strong> {user.role}</p>
              <p className="card-text"><strong>Account Created At:</strong> {new Date(user.createdAt).toLocaleString()}</p>
              <p className="card-text"><strong>Last Updated At:</strong> {new Date(user.updatedAt).toLocaleString()}</p>
              <p className="card-text"><strong>Provider:</strong> {user.provider}</p>
              <p className="card-text"><strong>Email Verified:</strong> {user.emails?.[0]?.verified ? 'Yes' : 'No'}</p>
              <button className="btn btn-danger mt-3" onClick={handleLogout}>Logout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
