import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import CarDetailsModal from './CarDetailsModal.js';
import { favoriteurl, apiUrl, authUrl } from '../config.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaCar, FaPalette, FaListUl, FaHeart, FaInfoCircle, FaWhatsapp } from 'react-icons/fa';

const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .replace(/\b\w/g, char => char.toUpperCase());
};

const formatPrice = (price) => {
  
  const roundedPrice = Math.round(price);
  return roundedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const generateWhatsAppMessage = (car) => {
  return `Details of car interested in:\nModel: ${capitalizeWords(car.model)}\nPrice: PKR ${formatPrice(car.price)}\nColor: ${capitalizeWords(car.color)}\n I want to enquire that:`;
};

const CarList = ({ cars }) => {
  const [selectedCar, setSelectedCar] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);
  const [showLoginPromptForCarId, setShowLoginPromptForCarId] = useState(null);
  const [carImages, setCarImages] = useState({});

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const authResponse = await fetch(`${authUrl}/login/success`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (authResponse.ok) {
          setUserId(localStorage.getItem('id'));
        }
      } catch (err) {
        console.error('Error fetching auth details:', err);
        setError(err);
      }
    };

    const fetchFavorites = async () => {
      if (userId) {
        try {
          const response = await fetch(`${favoriteurl}/favorites?userId=${userId}`);

          if (!response.ok) {
            throw new Error('Failed to fetch favorites');
          }
          const data = await response.json();
          setFavorites(data.favorites || []);
        } catch (error) {
          console.error('Error fetching favorites:', error);
          setError(error);
        }
      }
    };

    const fetchCarImages = async () => {
      try {
        const imagesPromises = cars.map(car =>
          axios.get(`${apiUrl}/${car.car_id}/images`).then(response => ({
            carId: car.car_id,
            images: response.data
          }))
        );

        const imagesResponses = await Promise.all(imagesPromises);
        const imagesByCarId = imagesResponses.reduce((acc, { carId, images }) => {
          acc[carId] = images;
          return acc;
        }, {});

        setCarImages(imagesByCarId);
      } catch (err) {
        console.error('Error fetching car images:', err);
        setError(err);
      }
    };

    fetchDetails().then(() => {
      fetchFavorites();
      fetchCarImages();
    });

    const handleClickOutside = () => {
      setShowLoginPromptForCarId(null);
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [userId, cars]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        closeModal();
      }
    };

    if (selectedCar) {
      window.addEventListener('keydown', handleEsc);
    } else {
      window.removeEventListener('keydown', handleEsc);
    }

    return () => window.removeEventListener('keydown', handleEsc);
  }, [selectedCar]);

  const openModal = (carId) => {
    const car = cars.find(car => car.car_id === carId);
    setSelectedCar(car);
  };

  const closeModal = () => {
    setSelectedCar(null);
  };

  const handleFavorite = async (carId) => {
    if (userId === null) {
      setShowLoginPromptForCarId(carId);
      return;
    }

    const isFavorite = favorites.includes(carId);
    const url = isFavorite ? '/remove' : '/add';

    try {
      await axios.post(`${favoriteurl}${url}`, { userId, carId });
      setFavorites(prevFavorites =>
        isFavorite
          ? prevFavorites.filter(id => id !== carId)
          : [...prevFavorites, carId]
      );
    } catch (error) {
      console.error('Error updating favorite:', error);
    }
  };


  const handleWhatsAppClick = (car) => {
    // Replace with your actual event values
    const eventValue = {
      event_category: 'WhatsApp',
      event_action: 'click',
      event_label: car.model,
      value: car.price
    };

    // Send event to Google Analytics
    if (window.gtag) {
      window.gtag('event', 'click', eventValue);
    }

    console.log(`WhatsApp icon clicked for car: ${car.model}`);
    // Additional handling code here
  };
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const renderTooltip = (props) => (
    <Tooltip id="login-tooltip" {...props}>
      <span>Please log in to add to favorites. <a href="/login">Login</a></span>
    </Tooltip>
  );

  const CarImageGallery = ({ images, activeImageId }) => {
    if (!images || images.length === 0) {
      return null;
    }

    const activeImage = images.find(image => image.image_id === activeImageId);
    const imageUrl = activeImage ? activeImage.url : images[0].url;

    return (
      <div className="car-image-gallery" style={{ height: '200px', overflow: 'hidden' }}>
        <img
          src={`${imageUrl}`}
          className="card-img-top"
          alt="Car"
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </div>
    );
  };

  return (
    <>
      {cars.length === 0 ? (
        <div className="col-12">
          <div className="no-cars-message">No cars match your filter criteria.</div>
        </div>
      ) : (
        cars.map(car => (
          <div key={car.car_id} className="col-lg-4 col-md-6 mb-4">
            <div className="card car-card">
              <CarImageGallery images={carImages[car.car_id] || []} activeImageId={car.activeImageId} />
              <div className="card-body">
                <h5 className="card-title">{capitalizeWords(car.model)}</h5>
                <p className="card-text">
                  <FaCar /> <strong>Company:</strong> {capitalizeWords(car.company)}<br />
                  <strong>Price:</strong> {parseFloat(car.price) === 0.00 ? "( Car hasn't arrived )" : `PKR ${formatPrice(car.price)}`}<br />
                  <FaPalette /> <strong>Color:</strong> {capitalizeWords(car.color)}<br />
                  <FaListUl /> <strong>Type:</strong> {capitalizeWords(car.type)}<br />
                </p>
                <div className="d-flex position-relative">
                  <button
                    type="button"
                    className="btn-sm btn-primary me-2"
                    onClick={() => openModal(car.car_id)}
                  >
                    <FaInfoCircle className='mb-1' /> Details
                  </button>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip}
                    show={showLoginPromptForCarId === car.car_id}
                  >
                    <button
                      type="button"
                      className={`btn-sm ${userId === null ? 'btn-secondary' : (favorites.includes(car.car_id) ? 'btn-danger' : 'btn-secondary')}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (userId === null) {
                          setShowLoginPromptForCarId(car.car_id);
                        } else {
                          handleFavorite(car.car_id);
                        }
                      }}
                    >
                      <FaHeart />
                    </button>
                  </OverlayTrigger>
                  <a
                    href={`https://wa.link/x44mw1?text=${encodeURIComponent(generateWhatsAppMessage(car))}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-sm btn-success ms-2"
                    style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                    onClick={() => handleWhatsAppClick(car)}
                  >
                    <FaWhatsapp />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))
      )}

      {selectedCar && (
        <CarDetailsModal car={selectedCar} carImages={carImages[selectedCar.car_id] || []} closeModal={closeModal} />
      )}
    </>
  );
};

export default CarList;
