import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import { mainurl, ticketurl, userUrl } from '../config.js';
import moment from 'moment';

const SupportTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const socket = useRef(null);
  const messagesEndRef = useRef(null);
  const role = localStorage.getItem('role');

  useEffect(() => {
    socket.current = io(mainurl);

    const fetchTickets = async () => {
      try {
        const response = await axios.get(`${ticketurl}`);
        setTickets(response.data);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };

    fetchTickets();

    return () => {
      socket.current.disconnect();
    };
  }, []);

  useEffect(() => {
    if (selectedTicket) {
      const fetchMessages = async () => {
        try {
          const response = await axios.get(`${ticketurl}/usermessages?ticketId=${selectedTicket}`);
          setMessages(response.data);
        } catch (error) {
          console.error('Error fetching messages:', error);
        }
      };

      const fetchUserDetails = async (customerId) => {
        try {
          const response = await axios.get(`${userUrl}/user/${customerId}`);
          setUserInfo(response.data.user);
        } catch (error) {
          console.error('Error fetching User Details:', error);
        }
      };

      fetchMessages();
      const selectedTicketData = tickets.find(ticket => ticket.id === selectedTicket);
      if (selectedTicketData) {
        fetchUserDetails(selectedTicketData.customerId);
      }

      socket.current.on('receiveMessage', (newMessage) => {
        if (newMessage.ticketId === selectedTicket) {
          setMessages((prevMessages) => [...prevMessages, newMessage]);
        }
      });

      socket.current.emit('joinTicket', selectedTicket);
    }

    return () => {
      socket.current.off('receiveMessage');
    };
  }, [selectedTicket, tickets]);

  const sendMessage = async () => {
    if (message && selectedTicket) {
      const newMessage = { ticketId: selectedTicket, text: message, senderRole: role };
      try {
        await axios.post(`${ticketurl}/sendmessage`, newMessage);
        socket.current.emit('sendMessage', newMessage);
        setMessage('');
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const deleteTicket = async () => {
    if (selectedTicket) {
      try {
        await axios.delete(`${ticketurl}/${selectedTicket}`);
        setTickets((prevTickets) => prevTickets.filter(ticket => ticket.id !== selectedTicket));
        setSelectedTicket(null);
        setMessages([]);
      } catch (error) {
        console.error('Error deleting ticket:', error);
      }
    }
  };

  const groupMessagesByDate = (messages) => {
    const grouped = messages.reduce((acc, message) => {
      const date = moment(message.createdAt).format('YYYY-MM-DD');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {});
    return grouped;
  };

  const renderMessages = (messages) => {
    const groupedMessages = groupMessagesByDate(messages);
    return Object.entries(groupedMessages).map(([date, msgs]) => (
      <div key={date}>
        <div style={{ textAlign: 'center', margin: '10px 0', fontWeight: 'bold' }}>
          {moment(date).format('MMMM Do YYYY')}
        </div>
        {msgs.map((msg) => (
          <div
            key={msg.id}
            className="mb-2"
            style={{
              textAlign: msg.senderRole === role ? 'right' : 'left',
              backgroundColor: msg.senderRole === role ? '#dcf8c6' : '#ffffff',
              padding: '5px 10px',
              borderRadius: '10px',
              maxWidth: '70%',
              marginLeft: msg.senderRole === role ? 'auto' : '0',
              marginRight: msg.senderRole === role ? '0' : 'auto',
            }}
          >
            <div>
              <strong>{msg.senderRole === 'admin' ? 'Admin:' : userInfo?.username || 'User:'}</strong> {msg.text}
            </div>
            <div style={{ fontSize: '0.8rem', color: '#888', textAlign: 'right' }}>
              {moment(msg.createdAt).format('h:mm A')}
            </div>
          </div>
        ))}
      </div>
    ));
  };

  return (
    <div className="container mt-5">
      <h2>Support Tickets</h2>
      <div className="row">
        <div className="col-12 col-md-4">
          <ul className="list-group">
            {tickets.map(ticket => (
              <li
                key={ticket.id}
                className={`list-group-item ${selectedTicket === ticket.id ? 'active' : ''}`}
                onClick={() => setSelectedTicket(ticket.id)}
                style={{ cursor: 'pointer' }}
              >
                Ticket ID: {ticket.id}
              </li>
            ))}
          </ul>
        </div>
        <div className="col-12 col-md-8">
          {selectedTicket && (
            <>
              <h4>Ticket ID: {selectedTicket}</h4>
              <div className="border p-3 mb-3" style={{ height: '300px', overflowY: 'scroll' }}>
                {renderMessages(messages)}
                <div ref={messagesEndRef} />
              </div>
              {userInfo && (
                <div className="text-right mb-3">
                  <button
                    className="btn btn-info btn-sm"
                    onClick={() => alert(`User Details: \nUsername: ${userInfo.username}\nEmail: ${userInfo.email}`)}
                  >
                    View User Details
                  </button>
                </div>
              )}
              <input
                type="text"
                placeholder="Type your message"
                className="form-control mb-2"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    sendMessage();
                  }
                }}
              />
              <div className='d-flex flex-column flex-md-row'>
                <button className="btn btn-success mb-2 mb-md-0" onClick={sendMessage}>
                  Send
                </button>
                <button className="btn btn-danger ml-md-2" onClick={deleteTicket}>
                  Delete
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SupportTickets;
