import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Sidebar.css';

const AdminSidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLinkClick = (path) => {
    if (window.innerWidth < 768) {
      setIsCollapsed(true);
    }
    navigate(path);
  };

  return (
    <div className="admin-sidebar-container">
      <button
        className="btn btn-primary d-md-none"
        type="button"
        onClick={toggleSidebar}
        style={{ position: 'fixed', top: '10px', left: '10px', zIndex: 1000 }}
      >
        ☰
      </button>
      <div className={`admin-sidebar bg-dark text-white p-3 ${isCollapsed ? 'collapse' : ''}`}>
        <h2 className="text-center mb-4">Admin Panel</h2>
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link className="nav-link text-white" to="addcar" onClick={() => handleLinkClick('addcar')}>
              Add Car
            </Link>
          </li>
          
          <li className="nav-item">
            <Link className="nav-link text-white" to="stock" onClick={() => handleLinkClick('stock')}>
              Edit/Delete Stock
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link text-white" to="soldcars" onClick={() => handleLinkClick('soldcars')}>
              Sold cars
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link text-white" to="users" onClick={() => handleLinkClick('currentadmins')}>
              Current Users
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link text-white" to="/" onClick={() => handleLinkClick('/home')}>
              Home
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdminSidebar;
