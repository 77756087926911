// export const mainurl = 'http://localhost:8080/'
export const mainurl = 'https://backend.ckzcars.com/'

// export const mainurl = 'https://ckzcars-e853f3d944eb.herokuapp.com/'


export const apiUrl = `${mainurl}cars`
    export const userUrl = `${mainurl}admin`
    export const authUrl = `${mainurl}auth`
export const ticketurl = `${mainurl}tickets`
export const favoriteurl = `${mainurl}favorites`

