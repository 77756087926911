import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from '../config.js';

const SetActiveCarImg = () => {
  const { id } = useParams(); // Car ID from the route parameters
  const car_id = id
  const [images, setImages] = useState([]);
  const [activeImage, setActiveImage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${apiUrl}/${car_id}/images`);
        console.log(response.data);
        setImages(response.data);
        const currentActive = response.data.find(image => image.active);
        setActiveImage(currentActive ? currentActive.image_id : null);
      } catch (err) {
        console.error('Error fetching images:', err);
        setError('Failed to fetch images.');
      }
    };

    fetchImages();
  }, [car_id]);

  const handleSetActive = async (image_id) => {
    try {
      await fetch(`${apiUrl}/${car_id}/setActiveImage`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ image_id,car_id })
    
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setActiveImage(image_id);
      })
      .catch(error => console.error('Error:', error));
      
    } catch (err) {
      console.error('Error setting active image:', err);
      alert('Failed to update active image.');
    }
  };

  return (
    <div className="container px-md-3 px-lg-4">
      <h1 className="text-center mb-4">Set Active Image for Car {car_id}</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="row">
        {images.length === 0 ? (
          <div className="col-12">
            <p>No images available for this car.</p>
          </div>
        ) : (
          images.map(image => (
            <div key={image.image_id} className="col-md-4 mb-3">
              <div className="card">
                <img src={`${image.url}`} alt={`Car ${car_id}`} className="card-img-top" />
                <div className="card-body">
                  <button
                    className={`btn ${activeImage === image.image_id ? 'btn-success' : 'btn-primary'}`}
                    onClick={() => handleSetActive(image.image_id)}
                  >
                    {activeImage === image.image_id ? 'Active' : 'Set as Active'}
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SetActiveCarImg;
