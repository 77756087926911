import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination } from 'swiper/modules';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const formatPrice = (price) => {
  // Convert to integer and format with commas
  const roundedPrice = Math.round(price);
  return roundedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const CarCarousel = ({ cars }) => {
  return (
    <Swiper
      modules={[Autoplay, Pagination]}
      slidesPerView={1} // Show one car at a time
      spaceBetween={20}
      speed={600}
      freeMode={true}
      loop={true}
      autoplay={{
        delay: 1500,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }} // Add pagination dots
    >
      {cars.map((car) => {
        const activeImage = car.images.find((image) => image.image_id === car.activeImageId) || car.images[0];
        return (
          <SwiperSlide key={car.car_id}>
            <div className="container bg-secondary bg-opacity-10 p-4 rounded-4">
              <div className="row align-items-center">
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                  {activeImage ? (
                    <img
                      src={`${activeImage.url}`}
                      alt={car.model}
                      className="img-fluid rounded-3"
                      style={{
                        height: '400px',
                        objectFit: 'cover', 
                      }}
                    />
                  ) : (
                    <p>No image available</p>
                  )}
                </div>
                <div className="col-12 col-md-6 d-flex flex-column justify-content-center ps-md-4">
                  <h3 className="h4 mb-2">{car.model}</h3>
                  <p className="mb-2">
                    <strong>Color:</strong> {car.color}
                  </p>
                  <p className="mb-2">
                    <strong>Mileage:</strong> {car.mileage} km
                  </p>
                  <p className="text-muted mb-0">
                    <strong>Price:</strong> PKR {formatPrice(car.price)}
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default CarCarousel;
