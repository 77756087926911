import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import { mainurl, ticketurl } from '../config.js';
import { FaFacebook, FaWhatsapp, FaInstagram } from 'react-icons/fa';
import moment from 'moment';

const Ticket = () => {
  const [reason, setReason] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [activeTicketId, setActiveTicketId] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const socket = useRef(null);
  const messagesEndRef = useRef(null);
  const scrollPosRef = useRef(0); // Ref to track scroll position
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('id');
  const role = localStorage.getItem('role');
  const pageTop = useRef(0);

  useEffect(() => {
    const checkLoginStatus = () => {
      if (token && userId) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    };

    checkLoginStatus();
  }, [token, userId]);

  useEffect(() => {
    if (!isLoggedIn) return;

    socket.current = io(mainurl);

    const fetchTickets = async () => {
      try {
        const response = await axios.get(`${ticketurl}/usertickets?customerId=${userId}`);
        setTickets(response.data);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };

    fetchTickets();

    return () => {
      socket.current.disconnect();
    };
  }, [userId, isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn || !activeTicketId) return;

    const fetchMessages = async () => {
      try {
        const response = await axios.get(`${ticketurl}/usermessages?ticketId=${activeTicketId}`);
        setMessages(response.data);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();

    socket.current.on('receiveMessage', (newMessage) => {
      if (newMessage.ticketId === activeTicketId) {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
    });

    socket.current.emit('joinTicket', activeTicketId);
    socket.current.emit('requestMessages', activeTicketId);

    return () => {
      socket.current.off('receiveMessage');
    };
  }, [activeTicketId, isLoggedIn]);

  const createTicket = async () => {
    if (!token) {
      alert('Please log in to create a ticket.');
      return;
    }

    try {
      const response = await axios.post(`${ticketurl}`, { customerId: userId, reason });
      setReason('');
      setActiveTicketId(response.data.ticketId);
      setMessages([]);
      const updatedTickets = await axios.get(`${ticketurl}/usertickets?customerId=${userId}`);
      setTickets(updatedTickets.data);
    } catch (error) {
      console.error('Error creating ticket:', error);
    }

    window.scrollTo(0, pageTop.current);
  };

  const sendMessage = async () => {
    if (message && activeTicketId) {
      const newMessage = { ticketId: activeTicketId, text: message, senderRole: role };
      
      // Save current scroll position
      const scrollPosition = window.scrollY;
      scrollPosRef.current = scrollPosition;

      try {
        await axios.post(`${ticketurl}/sendmessage`, newMessage);
        socket.current.emit('sendMessage', newMessage);
        setMessage('');
      } catch (error) {
        console.error('Error sending message:', error);
      }

      // Restore scroll position after sending
      setTimeout(() => {
        window.scrollTo(0, scrollPosRef.current);
      }, 0);
    }
  };

  const deleteTicket = async () => {
    if (token && activeTicketId) {
      try {
        await axios.delete(`${ticketurl}/${activeTicketId}`);
        setMessages([]);
        setActiveTicketId(null);
        const updatedTickets = await axios.get(`${ticketurl}/usertickets?customerId=${userId}`);
        setTickets(updatedTickets.data);
      } catch (error) {
        console.error('Error deleting ticket:', error);
      }
    }
  };

  const handleTicketChange = (ticketId) => {
    setActiveTicketId(ticketId);
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage();
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const groupMessagesByDate = (messages) => {
    const grouped = messages.reduce((acc, message) => {
      const date = moment(message.createdAt).format('YYYY-MM-DD');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {});
    return grouped;
  };

  const renderMessages = (messages) => {
    const groupedMessages = groupMessagesByDate(messages);
    return Object.entries(groupedMessages).map(([date, msgs]) => (
      <div key={date}>
        <div style={{ textAlign: 'center', margin: '10px 0', fontWeight: 'bold' }}>
          {moment(date).format('MMMM Do YYYY')}
        </div>
        {msgs.map((msg) => (
          <div
            key={msg.id}
            className="mb-2"
            style={{
              textAlign: msg.senderRole === role ? 'right' : 'left',
              backgroundColor: msg.senderRole === role ? '#dcf8c6' : '#ffffff',
              padding: '5px 10px',
              borderRadius: '10px',
              maxWidth: '70%',
              marginLeft: msg.senderRole === role ? 'auto' : '0',
              marginRight: msg.senderRole === role ? '0' : 'auto',
            }}
          >
            <div>
              <strong>{msg.senderRole === 'admin' ? 'Admin:' : 'You:'}</strong> {msg.text}
            </div>
            <div style={{ fontSize: '0.8rem', color: '#888', textAlign: 'right' }}>
              {moment(msg.createdAt).format('h:mm A')}
            </div>
          </div>
        ))}
      </div>
    ));
  };

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-center align-items-center mb-4">
        <a
          href="https://www.facebook.com/people/Ckzcars/100078906308800/"
          className="text-decoration-none"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook size={32} />
        </a>
        <div className="mx-3 text-center">
          <a
            href="https://wa.link/x44mw1"
            className="text-decoration-none"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp size={32} />
          </a>
        </div>
        <a
          href="https://www.instagram.com/ckzcars/"
          className="text-decoration-none"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram size={32} />
        </a>
        <div className="ms-3" style={{ fontSize: '14px', color: '#000' }}>
          <span className="fw-bold">Call Us: </span>
          <a href="tel:03002111630" style={{ textDecoration: 'none', color: 'inherit' }}>
            03002111630
          </a>
          {' / '}
          <a href="tel:03003515549" style={{ textDecoration: 'none', color: 'inherit' }}>
            03003515549
          </a>
        </div>
      </div>

      {isLoggedIn ? (
        <div>
          <h2>Customer Support Chat:</h2>

          <div className="mb-3">
            <form onSubmit={e => e.preventDefault()}>
              <div className="mb-3">
                <label htmlFor="reason" className="form-label">Reason for Contact:</label>
                <input
                  type="text"
                  className="form-control"
                  id="reason"
                  value={reason}
                  onChange={handleReasonChange}
                />
              </div>
              <button type="button" className="btn btn-primary" onClick={createTicket}>
                Create Ticket
              </button>
            </form>
          </div>

          {activeTicketId && (
            <div>
              <h3>Messages</h3>
              <div style={{ height: '400px', overflowY: 'scroll', border: '1px solid #ddd', padding: '10px' }}>
                {renderMessages(messages)}
                <div ref={messagesEndRef} />
              </div>

              <form onSubmit={handleSubmit}>
                <div className="input-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type your message..."
                  />
                  <button className="btn btn-primary" type="submit">Send</button>
                </div>
              </form>

              <button className="btn btn-danger mt-3" onClick={deleteTicket}>
                Delete Ticket
              </button>
            </div>
          )}

          <h3>Your Tickets</h3>
          <ul className="list-group">
            {tickets.map(ticket => (
              <li
                key={ticket.id}
                className="list-group-item"
                onClick={() => handleTicketChange(ticket.id)}
                style={{ cursor: 'pointer' }}
              >
                {ticket.reason} - {moment(ticket.createdAt).format('YYYY-MM-DD HH:mm')}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="text-center">
          <h2>Please log in to access the support chat via website. To contact without logging in use the whatsapp icon above.</h2>
        </div>
      )}
    </div>
  );
};

export default Ticket;
