import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { authUrl } from '../config.js';

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token) {
      localStorage.setItem('token', token);
      navigate('/'); 
    }
  }, [navigate]);

  const handleGoogleLogin = () => {
    window.location.href = `${authUrl}/google/callback`; 
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow">
            <div className="card-body text-center">
              <h3 className="card-title mb-4">Login with Google</h3>
              <button className="btn btn-danger w-100" onClick={handleGoogleLogin}>
                Login with Google
              </button>
              <div className="mt-3 text-center">
                <p>Not registered? <Link to="/signup">Sign up</Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
