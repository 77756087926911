import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay } from 'swiper/modules';
import { FaCar, FaPalette, FaTools, FaTachometerAlt, FaCheckCircle, FaKey, FaLeaf, FaLightbulb, FaCalendarCheck, FaCalendarTimes } from 'react-icons/fa';
import './CarDetailsModal.css'; // Import the CSS file for custom styles

const CarDetailsModal = ({ car, closeModal, carImages }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === 'Escape') {
        setSelectedImage(null);
      }
    };

    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, []);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseImageModal = () => {
    setSelectedImage(null);
  };

  // Utility function to capitalize the first letter and lowercase the rest
  const capitalizeFirstLetter = (text) => {
    if (!text) return '';
    return text
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const renderFeatureWithIcon = (feature) => {
    let icon;
    const featureText = capitalizeFirstLetter(feature.trim());
    if (featureText.includes('Seat')) icon = <FaCar />;
    else if (featureText.includes('Key')) icon = <FaKey />;
    else if (featureText.includes('Multi Media')) icon = <FaTools />;
    else if (featureText.includes('Parking')) icon = <FaCar />;
    else if (featureText.includes('Mirrors')) icon = <FaCar />;
    else if (featureText.includes('Radar')) icon = <FaTachometerAlt />;
    else if (featureText.includes('Traction')) icon = <FaCar />;
    else if (featureText.includes('Lane')) icon = <FaCar />;
    else if (featureText.includes('Glasses')) icon = <FaCar />;
    else if (featureText.includes('Air')) icon = <FaCar />;
    else if (featureText.includes('Eco')) icon = <FaLeaf />;
    else if (featureText.includes('Head Lamps')) icon = <FaLightbulb />;
    else icon = <FaCheckCircle />;

    return (
      <li key={feature} className="feature-item">
        {icon}
        <span className="feature-text">{featureText}</span>
      </li>
    );
  };

  const featureList = car.features ? car.features.split(',').map(renderFeatureWithIcon) : 'N/A';

  // Format the price with commas
  const formattedPrice = car.price ? Number(car.price).toLocaleString() : 'N/A';

  return (
    <>
      <div className="modal fade show" id="carDetailsModal" tabIndex="-1" role="dialog" aria-labelledby="carDetailsModalLabel" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="carDetailsModalLabel">Car Details</h5>
              <button type="button" className="close" onClick={closeModal} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  {/* Swiper Carousel for Car Images */}
                  <Swiper
                    modules={[Autoplay]}
                    slidesPerView={'auto'}
                    spaceBetween={20}
                    speed={1000} // Set the speed to 1 second
                    freeMode={true}
                    loop={true}
                    autoplay={{
                      delay: 3000, // Set the delay to 3 seconds
                      disableOnInteraction: false,
                    }}
                    pagination={false} // Hide the pagination dots
                    scrollbar={{ draggable: true }} // Make it scrollable
                    breakpoints={{
                      1024: {
                        slidesPerView: 3,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                      480: {
                        slidesPerView: 1,
                      },
                    }}
                  >
                    {carImages && carImages.length > 0 ? (
                      carImages.map((image, index) => (
                        <SwiperSlide key={index}>
                          <img
                            src={`${image.url}`}
                            className="img-fluid"
                            alt={`Car model ${car.model} - Slide ${index + 1}`}
                            style={{ width: '100%', height: '200px', objectFit: 'cover', cursor: 'pointer' }}
                            onClick={() => handleImageClick(`${image.url}`)}
                          />
                        </SwiperSlide>
                      ))
                    ) : (
                      <SwiperSlide>
                        <p>No images available</p>
                      </SwiperSlide>
                    )}
                  </Swiper>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6 col-lg-3">
                  <p><FaCar /> <strong>Model:</strong> {capitalizeFirstLetter(car.model)}</p>
                </div>
                <div className="col-md-6 col-lg-3">
                  <p><FaCar /> <strong>Company:</strong> {capitalizeFirstLetter(car.company) || 'N/A'}</p>
                </div>
                <div className="col-md-6 col-lg-3">
                  <p><FaPalette /> <strong>Color:</strong> {capitalizeFirstLetter(car.color)}</p>
                </div>
                <div className="col-md-6 col-lg-3">
                  <p><strong>Price:</strong> PKR {formattedPrice}</p>
                </div>
                <div className="col-md-6 col-lg-3">
                  <p><FaCar /> <strong>Type:</strong> {capitalizeFirstLetter(car.type) || 'N/A'}</p>
                </div>
                <div className="col-md-6 col-lg-3">
                  <p><FaTachometerAlt /> <strong>Mileage:</strong> {car.mileage || 'N/A'} km</p>
                </div>
                <div className="col-md-6 col-lg-3">
                  <p>{car.registered ? <FaCalendarCheck /> : <FaCalendarTimes />} <strong>Registered:</strong> {car.registered ? 'Yes' : 'No'}</p>
                </div>
                <div className="col-md-6 col-lg-3">
                  <p><FaCheckCircle /> <strong>Grade:</strong> {capitalizeFirstLetter(car.grade) || 'N/A'}</p>
                </div>
                <div className="col-md-6 col-lg-3">
                  <p><FaTools /> <strong>Touch Up:</strong> {capitalizeFirstLetter(car.touch_up) || 'N/A'}</p>
                </div>
                <div className="col-md-12">
                  <p><strong>Features:</strong></p>
                  <ul className="feature-list">
                    {featureList}
                  </ul>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
            </div>
          </div>
        </div>
      </div>

      {/* Full-Size Image Modal */}
      {selectedImage && (
        <div className="modal fade show" id="fullSizeImageModal" tabIndex="-1" role="dialog" aria-labelledby="fullSizeImageModalLabel" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content" style={{ border: 'none', background: 'none' }}>
              <div className="modal-body" style={{ padding: 0, position: 'relative' }}>
                <img
                  src={selectedImage}
                  className="img-fluid"
                  alt={`Full view of car model ${car.model}`}
                  style={{ width: '100%', height: 'auto', display: 'block' }}
                />
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={handleCloseImageModal}
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    color: 'white',
                    fontSize: '24px',
                  }}
                >
                  &times;
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CarDetailsModal;
