import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from '../config.js';

const EditCar = () => {
  const { carId } = useParams(); 
  const history = useNavigate();

  const [carDetails, setCarDetails] = useState({
    color: '',
    model: '',
    company: '',
    registered: false,
    grade: '',
    features: '',
    mileage: '',
    type: '',
    touch_up: '',
    price: '',
  });

  // Define fetchCarDetails using useCallback to ensure the function reference remains stable
  const fetchCarDetails = useCallback(async () => {
    try {
      const response = await axios.get(`${apiUrl}/${carId}`);
      const { data } = response;
      setCarDetails({
        model: data.model,
        color: data.color,
        type: data.type,
        mileage: data.mileage,
        company: data.company,
        features: data.features,
        registered: data.registered,
        touch_up: data.touch_up,
        grade: data.grade,
        price: data.price,
      });
    } catch (error) {
      console.error('Error fetching car details:', error);
    }
  }, [carId]);

  useEffect(() => {
    fetchCarDetails();
  }, [fetchCarDetails]); // Use fetchCarDetails in dependency array

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch(`${apiUrl}/updatecar/${carId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(carDetails),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Success:', data);
        alert('Car details updated successfully!');
        history('/admin/stock'); 
      } else {
        const errorData = await response.json();
        console.error('Error:', errorData);
        alert('Failed to update car details.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to update car details.');
    }
  };

  return (
    <div className="container">
      <h1 className="text-center mb-4">Edit Car Details</h1>
      <div className="form-container">
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="carModel">Car Model</label>
            <input
              type="text"
              className="form-control"
              id="carModel"
              name="model"
              value={carDetails.model}
              onChange={(e) =>
                setCarDetails({
                  ...carDetails,
                  model: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="carMileage">Car Mileage</label>
            <input
              type="number"
              className="form-control"
              id="carMileage"
              name="mileage"
              value={carDetails.mileage}
              onChange={(e) =>
                setCarDetails({
                  ...carDetails,
                  mileage: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="carColor">Car Color</label>
            <input
              type="text"
              className="form-control"
              id="carColor"
              name="color"
              value={carDetails.color}
              onChange={(e) =>
                setCarDetails({
                  ...carDetails,
                  color: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="carType">Car Type</label>
            <input
              type="text"
              className="form-control"
              id="carType"
              name="type"
              value={carDetails.type}
              onChange={(e) =>
                setCarDetails({
                  ...carDetails,
                  type: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="carCompany">Car Company</label>
            <input
              type="text"
              className="form-control"
              id="carCompany"
              name="company"
              value={carDetails.company}
              onChange={(e) =>
                setCarDetails({
                  ...carDetails,
                  company: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="carFeatures">Features</label>
            <input
              type="text"
              className="form-control"
              id="carFeatures"
              name="features"
              value={carDetails.features}
              onChange={(e) =>
                setCarDetails({
                  ...carDetails,
                  features: e.target.value,
                })
              }
            />
            <small className="form-text text-muted">
              Enter features separated by commas (e.g., Leather Seats, Sunroof).
            </small>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="carRegistered"
              name="registered"
              checked={carDetails.registered}
              onChange={(e) =>
                setCarDetails({
                  ...carDetails,
                  registered: e.target.checked,
                })
              }
            />
            <label className="form-check-label" htmlFor="carRegistered">
              Registered
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="carTouchUps">Touch-ups</label>
            <input
              type="text"
              className="form-control"
              id="carTouchUps"
              name="touch_up"
              value={carDetails.touch_up}
              onChange={(e) =>
                setCarDetails({
                  ...carDetails,
                  touch_up: e.target.value,
                })
              }
            />
            <small className="form-text text-muted">Enter any touch-ups or repairs needed.</small>
          </div>
          <div className="form-group">
            <label htmlFor="carGrade">Grade</label>
            <input
              type="text"
              className="form-control"
              id="carGrade"
              name="grade"
              value={carDetails.grade}
              onChange={(e) =>
                setCarDetails({
                  ...carDetails,
                  grade: e.target.value,
                })
              }
            />
            <small className="form-text text-muted">Enter any grade for the car.</small>
          </div>
          <div className="form-group">
            <label htmlFor="carPrice">Price</label>
            <input
              type="number"
              className="form-control"
              id="carPrice"
              name="price"
              value={carDetails.price}
              onChange={(e) =>
                setCarDetails({
                  ...carDetails,
                  price: e.target.value,
                })
              }
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Update Car Details
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditCar;
