import React from 'react';
import { ClipLoader } from 'react-spinners';
import carLogo from '../assets/carloading.png'; // Adjust path as necessary

const LoadingScreen = () => {
  return (
    <div style={styles.loaderContainer}>
      <div style={styles.logoContainer}>
        <img
          src={carLogo}
          alt="Car Logo"
          style={styles.logo}
        />
      </div>
      <ClipLoader color="#007bff" loading={true} size={150} style={styles.loader} />
      <h3 style={styles.loadingText}>Loading, please wait...</h3>
    </div>
  );
};

const styles = {
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f8f9fa',
    overflow: 'hidden', // Ensure the animation stays within bounds
  },
  logoContainer: {
    marginBottom: '20px',
    position: 'relative',
    width: '100px',
    height: 'auto',
    animation: 'moveLeft 3s linear infinite', // Continuous leftward movement
  },
  logo: {
    width: '100%',
    height: 'auto',
  },
  loader: {
    marginBottom: '20px', // Add some spacing between the logo and the loader
  },
  loadingText: {
    marginTop: '20px',
    color: '#007bff',
  },
};

// Keyframes for continuous leftward movement
const globalStyles = `
  @keyframes moveLeft {
    0% {
      transform: translateX(100%); // Start off-screen to the right
    }
    100% {
      transform: translateX(-100%); // Move to off-screen to the left
    }
  }
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = globalStyles;
document.head.appendChild(styleSheet);

export default LoadingScreen;
