import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion } from 'framer-motion';
import FeatureCarousel from '../Components/FeatureCarousel';
import CarCarousel from '../Components/CarCarousel';
import { apiUrl } from '../config';
import { Link } from 'react-router-dom';
import LoadingScreen from '../Components/LoadingScreen';
import { useInView } from 'react-intersection-observer';
import image from '../assets/landingpage5.png';

const Landingpage = () => {
  const [cars, setCars] = useState([]);
  const [carImages, setCarImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('token');

  const { ref: featureRef, inView: featureInView } = useInView({ triggerOnce: true });
  const { ref: stockRef, inView: stockInView } = useInView({ triggerOnce: true });
  const { ref: footerRef, inView: footerInView } = useInView({ triggerOnce: true });

  const fetchCarImages = useCallback(async (cars) => {
    try {
      const imagesPromises = cars.map(car =>
        axios.get(`${apiUrl}/${car.car_id}/images`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }).then(response => ({
          carId: car.car_id,
          images: response.data
        }))
      );

      const imagesResponses = await Promise.all(imagesPromises);
      const imagesByCarId = imagesResponses.reduce((acc, { carId, images }) => {
        acc[carId] = images;
        return acc;
      }, {});

      return imagesByCarId;
    } catch (err) {
      console.error('Error fetching car images:', err);
      throw err;
    }
  }, [token]);

  const fetchData = useCallback(async () => {
    try {
      const carsResponse = await axios.get(`${apiUrl}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      const carsData = carsResponse.data;

      const imagesByCarId = await fetchCarImages(carsData);

      setCars(carsData);
      setCarImages(imagesByCarId);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [fetchCarImages, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return <LoadingScreen />;
  }
  if (error) {
    return <div className="text-center">Error: {error.message}</div>;
  }

  return (
    <>
      <motion.div
        className='container-fluid bg-opacity-50'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.div
          className="jumbotron jumbotron-fluid text-white text-start"
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          transition={{ duration: 1, type: 'spring', stiffness: 50 }}
        >
          <div className="container" style={{ position: 'relative', zIndex: 2 }}>
            <div className="row d-flex align-items-center">
              <div className="col-md-6">
                <h3 className="display-4">Welcome to CKZ Cars</h3>
                <p className="lead fw-normal">CKZ Cars specializes in Japanese unregistered cars. We buy and sell a wide selection of premium vehicles.</p>
              </div>
              <div className="col-md-6 text-center text-md-end" style={{ overflow: 'hidden' }}>
                <img
                  src={image}
                  alt="CKZ Cars Logo"
                  className="img-fluid"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    transform: 'scale(1.1)',
                    filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))',
                  }}
                />
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          className="container my-5 text-center"
          ref={featureRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: featureInView ? 1 : 0 }}
          transition={{ duration: 1 }}
        >
          <h2 className="mb-4">Website Information</h2>
          <FeatureCarousel />
        </motion.div>

        <motion.div
          className="container my-5 text-center"
          ref={stockRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: stockInView ? 1 : 0 }}
          transition={{ duration: 1 }}
        >
          <h2 className="mb-4">Latest Stock</h2>
          <CarCarousel
            cars={cars.map(car => ({
              ...car,
              images: carImages[car.car_id] || []
            }))}
          />
          <div className="mt-4">
            <Link to='/cars'>
              <button className='btn btn-primary'>View Complete Stock</button>
            </Link>
          </div>
        </motion.div>

        <motion.div
          className="container mt-5 text-center"
          ref={footerRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: footerInView ? 1 : 0 }}
          transition={{ duration: 1 }}
        >
          <div className="row">
            <motion.div
              className="col-lg-4 mb-4"
              initial={{ x: -100 }}
              animate={{ x: 0 }}
              transition={{ duration: 1 }}
            >
              <div className="feature-box">
                <i className="fas fa-car fa-3x mb-2" />
                <h3>Wide Selection</h3>
                <p>Explore our diverse range of japanese cars from around the world.</p>
              </div>
            </motion.div>
            <motion.div
              className="col-lg-4 mb-4"
              initial={{ x: 100 }}
              animate={{ x: 0 }}
              transition={{ duration: 1 }}
            >
              <div className="feature-box">
                <i className="fas fa-hand-holding-usd fa-3x mb-2" />
                <h3>Buy &amp; Sell</h3>
                <p>Buy or sell your car hassle-free with our expert services.</p>
              </div>
            </motion.div>
            <motion.div
              className="col-lg-4 mb-4"
              initial={{ x: -100 }}
              animate={{ x: 0 }}
              transition={{ duration: 1 }}
            >
              <div className="feature-box">
                <i className="fas fa-phone-alt fa-3x mb-2" />
                <h3>Contact Us</h3>
                <p>Reach out to us for any inquiries or to schedule a test drive.</p>
              </div>
            </motion.div>
          </div>
        </motion.div>

        <motion.div
          className="footer-section mt-5 bg-light py-4 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: footerInView ? 1 : 0 }}
          transition={{ duration: 1 }}
        >
          <div className="container">
            <h3>We sell cars from top brands like Toyota, Honda, Daihatsu, Suzuki, and more.</h3>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default Landingpage;
