import React, { useState, useEffect } from 'react';
import Sidebar from '../Components/Sidebar.js';
import CarList from '../Components/CarList.js';
import { apiUrl, favoriteurl } from '../config.js';

const Cars = () => {
  const [cars, setCars] = useState([]);
  const [filteredCars, setFilteredCars] = useState([]);
  const [companyFilter, setCompanyFilter] = useState('');
  const [budgetFilter, setBudgetFilter] = useState('');
  const [colorSort, setColorSort] = useState('');
  const [carTypeSort, setCarTypeSort] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isFavoriteActive, setIsFavoriteActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: 'GET'
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCars(data);
        setFilteredCars(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching car data');
      } finally {
        setLoading(false);
      }
    };

    fetchCars();
  }, []);

  useEffect(() => {
    const fetchFilteredCars = async () => {
      console.log("filters applied");
      let url = `${apiUrl}?`;

      if (companyFilter && companyFilter !== 'Select Company') {
        url += `company=${companyFilter}&`;
      }

      if (budgetFilter) {
        url += `price_lte=${budgetFilter}&`;
      }

      if (colorSort && colorSort !== 'Select Color') {
        url += `color=${colorSort}&`;
      }

      if (carTypeSort && carTypeSort !== 'Select Car Type') {
        url += `type=${carTypeSort}&`;
      }

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setFilteredCars(data);
      } catch (error) {
        console.error('Error fetching filtered data:', error);
        setError('Error fetching filtered data');
      }
    };

    if (fetchTrigger) {
      fetchFilteredCars();
      setFetchTrigger(false); // Reset trigger state after fetching
    }
  }, [fetchTrigger, companyFilter, budgetFilter, colorSort, carTypeSort]);

  const applyFilters = () => {
    setFetchTrigger(true);
  };

  const resetFilters = () => {
    setCompanyFilter('');
    setBudgetFilter('');
    setColorSort('');
    setCarTypeSort('');
    setFetchTrigger(true);
  };

  const handleFav = async () => {
    const userId = localStorage.getItem('id');
    try {
      if (isFavoriteActive) {
        setFilteredCars(cars);
      } else {
        const response = await fetch(`${favoriteurl}/favorites?userId=${userId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const favorites = Array.isArray(data.favorites) ? data.favorites : [];
        const favoriteCars = cars.filter(car => favorites.includes(car.car_id));
        setFilteredCars(favoriteCars);
      }
      setIsFavoriteActive(prev => !prev);
    } catch (error) {
      console.error('Error fetching favorites:', error);
      setError('Error fetching favorite cars');
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(prev => !prev);
  };

  const handleSearch = (searchTerm) => {
    const lowercasedSearchTerm = (searchTerm || '').toString().toLowerCase();
    
    const filtered = cars.filter((car) => {
      console.log(car.model)
      const name = car.model || ''; // Adjust this if your property is named differently
      return name.toLowerCase().includes(lowercasedSearchTerm);
    });

    setFilteredCars(filtered);
  };

  const handleChange = (event) => {
    const searchTerm = event.target.value; // Extract the value from the event
    setSearchTerm(searchTerm); // Update state
    handleSearch(searchTerm); // Perform search
  };

  if (loading) {
    return <div className="container-fluid"><p>Loading...</p></div>;
  }

  if (error) {
    return <div className="container-fluid"><p>Error: {error}</p></div>;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar
          companyFilter={companyFilter}
          setCompanyFilter={setCompanyFilter}
          budgetFilter={budgetFilter}
          setBudgetFilter={setBudgetFilter}
          colorSort={colorSort}
          setColorSort={setColorSort}
          carTypeSort={carTypeSort}
          setCarTypeSort={setCarTypeSort}
          applyFilters={applyFilters}
          resetFilters={resetFilters}
          sidebarOpen={sidebarOpen}
          toggleSidebar={toggleSidebar}
        />
        <div className={`col-lg-9 mt-2 col-md-8 content ${sidebarOpen ? 'content-open' : ''} position-relative`}>
          <h1 className="mx-1">OUR STOCK</h1>
          <button 
            onClick={handleFav}
            className={`btn btn-sm position-absolute ${isFavoriteActive ? 'btn-danger' : 'btn-primary'}`} 
            style={{ top: '1.5rem', right: '1rem', padding: '0.25rem 0.5rem', display: 'flex', alignItems: 'center' }}
          >
            {isFavoriteActive ? 'View Your Favorite List ✗' : 'View Your Favorite List'}
          </button>
  
          <input
            type="text"
            className="form-control mb-5"
            placeholder="Search by car name..."
            value={searchTerm}
            onChange={handleChange} // Attach event handler
          />

          <div className="row">
            <CarList cars={filteredCars} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cars;
