import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; 

import { Autoplay } from 'swiper/modules'; 
import { Link } from 'react-router-dom';

const FeatureCarousel = () => {
  const featureContentStyle = {
    height: '150px', // Fixed height for the cards
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Center content vertically
    alignItems: 'center', // Center content horizontally
    padding: '20px', // Optional padding
    backgroundColor: '#f8f8f8', // Optional background color
    borderRadius: '10px', // Optional rounded corners
  };

  return (
    <Swiper
      modules={[Autoplay]} 
      slidesPerView={'auto'} 
      spaceBetween={20} 
      speed={600} 
      freeMode={true} 
      loop={true} 
      autoplay={{
        delay: 3000, 
        disableOnInteraction: false, 
      }}
      breakpoints={{
        1024: {
          slidesPerView: 3, 
        },
        768: {
          slidesPerView: 2, 
        },
        480: {
          slidesPerView: 1, 
        },
      }}
    >
      <SwiperSlide>
        <div style={featureContentStyle}>
          <Link to="/cars" className="feature-link">
            <h3 className="feature-title">Explore Our Stock</h3>
          </Link>
          <p className="feature-description">
            You can view all the available cars at <strong>our stock</strong>. Use filters to match your specific requirement.
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div style={featureContentStyle}>
          <Link tp="/aboutus" className="feature-link">
            <h3 className="feature-title">About Us</h3>
          </Link>
          <p className="feature-description">
            <strong>Our About Us</strong> will give you information about our business, directors, and our location.
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div style={featureContentStyle}>
          <Link to="/contactus" className="feature-link">
            <h3 className="feature-title">Contact Us</h3>
          </Link>
          <p className="feature-description">
            Contact us via WhatsApp or create a ticket at <strong>contact us</strong> describing your concern and we will get back to you as soon as possible.
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div style={featureContentStyle}>
          <Link to="/articles" className="feature-link">
            <h3 className="feature-title">Articles</h3>
          </Link>
          <p className="feature-description">Learn why you should use japanese cars instead of locally assembled cars.</p>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default FeatureCarousel;
