import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { apiUrl } from '../config.js';

const Stock = () => {
  const [cars, setCars] = useState([]);
  const [error, setError] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(null); // State to manage which dropdown is open

  useEffect(() => {
    fetchCars();
  }, []);

  const fetchCars = async () => {
    try {
      const response = await fetch(`${apiUrl}`);
      if (!response.ok) throw new Error('Failed to fetch cars');
      const data = await response.json();
      setCars(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching cars:', error);
      setError('Failed to fetch cars. Please try again.');
    }
  };

  const updateInStockStatus = async (carId) => {
    try {
      const response = await fetch(`${apiUrl}/markcaroutofstock`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ carId, instock: false })
      });

      if (response.ok) {
        alert('Car status updated successfully!');
        fetchCars();
      } else {
        const errorData = await response.json();
        console.error('Error updating car status:', errorData);
        alert('Failed to update car status.');
      }
    } catch (error) {
      console.error('Error updating car status:', error);
      alert('Failed to update car status.');
    }
  };

  const handleDropdownToggle = (carId) => {
    setOpenDropdown(openDropdown === carId ? null : carId); // Toggle dropdown for the current car
  };

  const dropdownMenuStyle = {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    backgroundColor: 'white',
    border: '1px solid #ddd',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 1000, // Ensures it appears above other content
    right: 0, // Align dropdown to the right side
  };

  const dropdownItemStyle = {
    padding: '8px 12px',
    textDecoration: 'none',
    color: '#333',
    borderBottom: '1px solid #ddd',
    cursor: 'pointer',
  };

  const dropdownItemLastStyle = {
    ...dropdownItemStyle,
    borderBottom: 'none',
  };

  return (
    <div className="container px-md-3 px-lg-4" style={{ overflowX: 'hidden' }}>
      <h1 className="text-center mb-4">Car Stock Management</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="table-responsive" style={{ overflowX: 'auto' }}>
        <table className="table table-striped" style={{ tableLayout: 'auto', width: '100%' }}>
          <thead>
            <tr>
              <th style={{ width: '10%' }}>Car ID</th>
              <th style={{ width: '15%' }}>Uploaded At</th>
              <th style={{ width: '15%' }}>Updated At</th>
              <th style={{ width: '10%' }}>Color</th>
              <th style={{ width: '15%' }}>Model</th>
              <th className="d-none d-md-table-cell" style={{ width: '10%' }}>Mileage</th>
              <th className="d-none d-md-table-cell" style={{ width: '10%' }}>Registered</th>
              <th className="d-none d-lg-table-cell" style={{ width: '10%' }}>Grade</th>
              <th style={{ width: '10%' }}>In Stock</th>
              <th style={{ width: '10%' }}>Touch Up</th>
              <th style={{ width: '15%' }}>Price</th>
              <th style={{ width: '10%' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {cars.map((car) => (
              <tr key={car.car_id} style={{ position: 'relative' }}>
                <td>{car.car_id}</td>
                <td>{car.createdAt ? new Date(car.createdAt).toLocaleDateString() : 'N/A'}</td>
                <td>{car.updatedAt ? new Date(car.updatedAt).toLocaleDateString() : 'N/A'}</td>
                <td>{car.color}</td>
                <td>{car.model}</td>
                <td className="d-none d-md-table-cell">{car.mileage}</td>
                <td className="d-none d-md-table-cell">{car.registered ? 'True' : 'False'}</td>
                <td className="d-none d-lg-table-cell">{car.grade}</td>
                <td>{car.instock.toString()}</td>
                <td>{car.touch_up}</td>
                <td>$ {car.price}</td>
                <td>
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => handleDropdownToggle(car.car_id)}
                    style={{ position: 'relative' }}
                  >
                    &#8230; {/* Three dots */}
                  </button>
                  {openDropdown === car.car_id && (
                    <div style={dropdownMenuStyle}>
                      <Link 
                        to={`/admin/edit/${car.car_id}`} 
                        className="dropdown-item"
                        style={dropdownItemStyle}
                      >
                        Edit
                      </Link>
                      <Link 
                        to={`/admin/setimg/${car.car_id}`} 
                        className="dropdown-item"
                        style={dropdownItemStyle}
                      >
                        Set Image
                      </Link>
                      <button 
                        onClick={() => updateInStockStatus(car.car_id)} 
                        className="dropdown-item"
                        style={dropdownItemLastStyle}
                      >
                        Mark as Sold
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Stock;
