import React from 'react';

const Footer = () => {
  const footerStyle = {
    backgroundColor: '#343a40',
    color: '#fff',
    textAlign: 'center',
    position: 'relative',
    bottom: 0,
    width: '100%',
  };

  return (
    <footer style={footerStyle}>
      <a href="#top" className="btn btn-custom scroll-link mb-2 mt-2">Back to Top</a>
      <p className="mb-0">© 2023 CKZ Cars. All Rights Reserved.</p>
      <p>New M. A. Jinnah Rd, Hyderabad Colony Karachi, Sindh</p>
    </footer>
  );
};

export default Footer;
