  import React, { useState, useEffect } from 'react';
import { apiUrl } from '../config.js';

const Sidebar = ({
  companyFilter,
  setCompanyFilter,
  budgetFilter,
  setBudgetFilter,
  colorSort,
  setColorSort,
  carTypeSort,
  setCarTypeSort,
  applyFilters,
  sidebarOpen,
  toggleSidebar
}) => {
  const [companies, setCompanies] = useState([]);
  const [colors, setColors] = useState([]);
  const [carTypes, setCarTypes] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    fetch(apiUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        const uniqueCompanies = [...new Set(data.map(car => car.company.trim().toLowerCase()))]
          .map(company => company.charAt(0).toUpperCase() + company.slice(1))
          .sort();

        const uniqueColors = [...new Set(data.map(car => car.color.trim().toLowerCase()))]
          .map(color => color.charAt(0).toUpperCase() + color.slice(1))
          .sort();

        const uniqueCarTypes = [...new Set(data.map(car => car.type.trim().toLowerCase()))]
          .map(type => type.charAt(0).toUpperCase() + type.slice(1))
          .sort();

        setCompanies(uniqueCompanies);
        setColors(uniqueColors);
        setCarTypes(uniqueCarTypes);
      })
      .catch(error => console.error('Error fetching car data:', error));
  }, []);

  const handleApplyFilters = () => {
    applyFilters({
      company: companyFilter,
      budget: budgetFilter,
      color: colorSort,
      carType: carTypeSort
    });
    if (sidebarOpen) {
      toggleSidebar();
    }
  };

  const handleResetFilters = () => {
    setCompanyFilter('');
    setBudgetFilter('');
    setColorSort('');
    setCarTypeSort('');

    applyFilters({
      company: '',
      budget: '',
      color: '',
      carType: ''
    });

    if (sidebarOpen) {
      toggleSidebar();
    }
  };

  return (
    <div className={`sidebar ${sidebarOpen ? 'sidebar-open' : ''}`}>
      <button className="btn btn-primary d-lg-none mt-3" id="sidebarToggle" onClick={toggleSidebar}>
        Filters
      </button>
      <h5 className="text-white px-3 py-2 mb-0">Filters</h5>
      <form id="filtersForm" className="px-3 pt-3" onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <label htmlFor="companyFilter" className="text-white">Company</label>
          <select
            className="form-control"
            id="companyFilter"
            value={companyFilter}
            onChange={(e) => setCompanyFilter(e.target.value)}
          >
            <option value="">Select Company</option>
            {companies.map(company => (
              <option key={company} value={company}>
                {company}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="budgetFilter" className="text-white">By Budget (Max)</label>
          <input
            type="number"
            className="form-control"
            id="budgetFilter"
            placeholder="Enter maximum budget"
            value={budgetFilter}
            onChange={(e) => setBudgetFilter(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="colorSort" className="text-white">Sorting by Color</label>
          <select
            className="form-control"
            id="colorSort"
            value={colorSort}
            onChange={(e) => setColorSort(e.target.value)}
          >
            <option value="">Select Color</option>
            {colors.map(color => (
              <option key={color} value={color}>
                {color}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="carTypeSort" className="text-white">Sorting by Car Type</label>
          <select
            className="form-control"
            id="carTypeSort"
            value={carTypeSort}
            onChange={(e) => setCarTypeSort(e.target.value)}
          >
            <option value="">Select Car Type</option>
            {carTypes.map(type => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="d-flex justify-content-between">
          <button type="button" className="btn btn-primary" onClick={handleApplyFilters}>
            Apply Filters
          </button>
          <button type="button" className="btn btn-secondary ml-3" onClick={handleResetFilters}>
            Reset Filters
          </button>
        </div>
      </form>
    </div>
  );
};

export default Sidebar;