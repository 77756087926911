import React from 'react';
import '../Components/styles.css'; 
import downloadimage from '../assets/ckzcarslogo.png'; 
import './styles/Aboutus.css'; 

export default function Aboutus() {
  return (
    <div className="page-background">
      <div className="mt-5 container text-center header-section">
        <h1>About Us</h1>
        <p>Welcome to our Hybrid Car Showroom Website, <br /> where we provide the stock of our best used hybrid cars imported from Japan.</p>
      </div>
      <div className="container container-opacity fw-bold">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <div className="text-center p-3">
              <h1 className='fs-1'>Our Info</h1>
              <p className='fs-5'>
                We are a reputable car showroom specializing in the buying and selling of hybrid used cars imported from Japan. Our goal is to offer high-quality, fuel-efficient vehicles that are both environmentally friendly and economically advantageous.
              </p>
              <p><strong>Owners:</strong> Sheikh Zeeshan Sami and Sheikh Mohsin Sami</p>
            </div>
          </div>
          <div className="col-md-6">
            <img src={downloadimage} className="img-fluid" alt="CKZ Cars Showroom Logo" />
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row text-center">
          <div className="col-md-12">
            <h2 className='fs-1'>Our Location</h2>
            <p className='fs-5 fw-medium'>New M. A. Jinnah Rd, Hyderabad Colony Karachi, Karachi City, Sindh</p>
            <iframe 
              className="map" 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3303.722123240663!2d73.0674485152575!3d33.70367278075057!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df8f549d98dcf3%3A0x1484c4ed10570bfb!2sNew%20M.%20A.%20Jinnah%20Rd%2C%20Hyderabad%20Colony%2C%20Karachi%20City%2C%20Sindh!5e0!3m2!1sen!2s!4v1633614097320!5m2!1sen!2s"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <h2>Why Choose Hybrid Cars?</h2>
          </div>
          <div className="col-md-3 m-2">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Fuel Efficiency</h5>
                <p className="card-text">
                  Hybrid cars combine the power of a gasoline engine with an electric motor, offering superior fuel economy compared to conventional vehicles.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 m-2">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Environmentally Friendly</h5>
                <p className="card-text">
                  Reduced emissions make hybrid cars a greener option, contributing to a cleaner environment and helping reduce carbon footprints.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 m-2">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Cost-Effective</h5>
                <p className="card-text">
                  Over time, the increased fuel efficiency and lower maintenance costs make hybrid cars a financially sound investment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
