import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminSidebar from './AdminSidebar.js';
import AddCar from './AddCar.js';
import EditCar from './EditCar.js';
import Landingpage from '../main/Landingpage.js';
import Stock from './Stock.js';
import './AdminPanel.css'; 
import CurrentUsers from './CurrentUsers.js';
import SupportTickets from './SupportTickets.js';
import OutOfStock from './OutOfStock.js';
import SetActiveCarImg from './SetActiveCarImg.js';

const AdminPanel = () => {
  return (
    <div className="admin-panel">
      <AdminSidebar />
      <div className="admin-content">
        <Routes>
          <Route path="/addcar" element={<AddCar />} />
          <Route path="/users" element={<CurrentUsers />} />
          <Route path="/home" element={<Landingpage />} />
          <Route path="/stock" element={<Stock />} />
          <Route path="/edit/:carId" element={<EditCar />} />
          <Route path="/support" element={<SupportTickets/>}/>
          <Route path="/soldcars" element={<OutOfStock/>}/>
          <Route path="/setimg/:id" element={<SetActiveCarImg/>}/>


        </Routes>
      </div>
    </div>
  );
};

export default AdminPanel;
