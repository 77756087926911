import React from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Articles() {
    return (
        <Container className="my-5">
          <Row className="text-center mb-4">
            <Col>
              <h1>Why Japanese Imported Cars are Better than Locally Assembled Cars in Pakistan</h1>
            </Col>
          </Row>
    
          <Row>
            <Col md={12}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title className="text-primary">Quality and Reliability</Card.Title>
                  <Card.Text>
                    Japanese car manufacturers are renowned for their rigorous quality control standards and meticulous attention to detail. Brands such as Toyota, Honda, and Nissan have built a global reputation for producing vehicles that excel in durability and dependability. The engineering precision and superior materials used in Japanese cars ensure that they perform consistently well over the years. In contrast, locally assembled cars in Pakistan often lack the same level of craftsmanship and quality assurance, leading to more frequent maintenance issues and a shorter lifespan.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
    
            <Col md={12}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title className="text-primary">Advanced Technology and Features</Card.Title>
                  <Card.Text>
                    Japanese imported cars are equipped with the latest technology and features, making them a step ahead in terms of innovation and user experience. From advanced safety systems like collision avoidance and lane-keeping assistance to cutting-edge infotainment systems, Japanese cars offer a range of modern conveniences and enhancements. These features not only improve the driving experience but also ensure a higher level of safety for passengers. In contrast, locally assembled cars in Pakistan often lag in incorporating the latest technology, which can affect both the performance and appeal of the vehicle.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
    
            <Col md={12}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title className="text-primary">Resale Value and Cost Efficiency</Card.Title>
                  <Card.Text>
                    Japanese imported cars tend to retain their value better than locally assembled vehicles. The strong reputation of Japanese brands for reliability and quality translates into higher resale prices, making them a more cost-effective choice in the long run. Additionally, the superior build quality and advanced engineering of Japanese cars result in lower maintenance and repair costs over time. While the initial investment might be higher, the long-term savings and resale value more than compensate for the difference, offering greater financial benefits to the owner.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
    
          <Row className="text-center">
            <Col>
              <Card className="bg-light">
                <Card.Body>
                  <Card.Title>Conclusion</Card.Title>
                  <Card.Text>
                    When choosing a vehicle, investing in a Japanese imported car is a decision that promises long-term benefits. With their superior quality, advanced technology, and higher resale value, Japanese cars offer unmatched reliability and cost efficiency. By opting for a Japanese imported car, consumers in Pakistan can enjoy a safer, more enjoyable driving experience and greater peace of mind. Make the smart choice today and experience the difference that Japanese engineering can make.
                  </Card.Text>
                  <Link to='/contactus'>
                  <Button variant="primary">Contact Us</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      );
    };
